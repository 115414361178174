import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { detailsRepository } from '../core/stores/details.repository';
import { Recommendation } from '../overview/overview.component';
import { Connector } from '../core/data-backend/models';
import { DetailsHeaderDisplayInfo } from './details-header/details-header.component';
import { StateClassPipe } from '../core/pipes/state-class.pipe';
import { ExtendedChargingStation } from '../core/helpers/transform-stations.helper';

@Component({
    selector: 'app-details',
    template: `
        <app-subnav></app-subnav>
        <app-details-header
            [displayInfo]="displayInfo$ | async"
        ></app-details-header>
        <router-outlet></router-outlet>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    station!: ExtendedChargingStation;
    displayInfo$ = new BehaviorSubject<DetailsHeaderDisplayInfo | null>(null);

    constructor(
        private route: ActivatedRoute,
        private _pipe: StateClassPipe,
        public detailsRepository: detailsRepository
    ) {
        this.route.params.pipe(
            takeUntil(this._destroying$)
        ).subscribe(params => {
            let id = params['id'];
            this.detailsRepository.setStationId(id)
        })
    }

    ngOnInit(): void {
        this.detailsRepository.station$.pipe(
            takeUntil(this._destroying$)
        ).subscribe((station: ExtendedChargingStation | null) => {
            if (!station) return;
            this.station = station;
            let displayInfo = {
                stationId: this.getDefault('stationId'),
                chargerModel: this.getDefault('chargerModel'),
                chargerVendor: this.getDefault('chargerVendor'),
                address: this.getDefault('address'),
                postalCode: this.getDefault('postalCode'),
                city: this.getDefault('city'),
                countryCode: this.getDefault('countryCode'),
                ocppVersion: this.getDefault('ocppVersion'),
                stateClassLastHeartbeatState: this.getStateClass('lastHeartbeatState'),
                stateClassLastChargingModelState: this.getStateClass('lastChargingModelState'),
                stateClassLastErrorState: this.getStateClass('lastErrorState'),
                overallState: this.getLastOverallState(),
                healthIcon: this.getHealthIcon(),
                healthValue: this.getHealthValue(),
                healthState: this.getHealthState(),
                softwareVersion: this.getSoftwareVersion(),
                recommendation: this.getRecommendation(),
                recommendationIcon: this.getRecommendationIcon(),
                customEvseId: this.getCustomEvseId(),
            }
            let copyInfo = {
                copyCharger: this.getCopyInfo([displayInfo.chargerModel, displayInfo.chargerVendor]),
                copyStation: this.getCopyInfo([displayInfo.stationId, displayInfo.customEvseId]),
                copyLocation: this.getCopyInfo([displayInfo.address, displayInfo.postalCode, displayInfo.city, displayInfo.countryCode]),
                copyVersion: this.getCopyInfo([displayInfo.ocppVersion, displayInfo.softwareVersion])
            }
            this.displayInfo$.next({...displayInfo, ...copyInfo})
        })
    }

    // gets the attribute or '-' if null
    getDefault(attr: string): string {
        let value = this.station[attr as keyof ExtendedChargingStation];
        if (!value) return '-'
        return value.toString();
    }

    // get the state of a certain attribute in css class format
    getStateClass(attr: string) {
        let state: string | undefined = undefined;
        if (this.station[attr as keyof ExtendedChargingStation] != null) {
            state = this.station[attr as keyof ExtendedChargingStation]?.toString();
        } else if (
            this.station.featuredConnector &&
            this.station.featuredConnector[attr as keyof Connector] != null
        ) {
            state = this.station.featuredConnector[attr as keyof Connector]?.toString();
        }
        if (!state) return this._pipe.transform("No Data");
        return this._pipe.transform(state);
    }

    // get last overall state - no data if null
    getLastOverallState(): string {
        return this.station.featuredConnector?.lastOverallState ?
            this.station.featuredConnector.lastOverallState : 'No Data'
    }

    // get the correct hi index icon 
    getHealthIcon(): string {
        return (
            !this.station.featuredConnector ||
            this.station.featuredConnector.lastOverallState == null ||
            this.station.featuredConnector.lastOverallState == 'No Data'
        ) ? 'gpp_bad' : 'health_and_safety';
    }

    // get the prepared health index value - '-' if empty
    getHealthValue(): number | string {
        return (
            !this.station.featuredConnector ||
            this.station.featuredConnector.lastHealthIndexValue == null
        ) ? '-' : this.station.featuredConnector.lastHealthIndexValue;
    }

    // get the health index state - no data if empty
    getHealthState(): string {
        if (!this.station.featuredConnector || this.station.featuredConnector.lastHealthIndexValue == null) {
            return this._pipe.transform("No Data");
        }
        // quick fix for some hi values of type string in demo data
        return this._pipe.transform(typeof this.station.featuredConnector.lastHealthIndexValue === 'string'
            ? parseInt(this.station.featuredConnector.lastHealthIndexValue)
            : this.station.featuredConnector.lastHealthIndexValue);
    }

    // get the software version - cut off to 14 chars if longer than that
    getSoftwareVersion(): string {
        if (!this.station.softwareVersion) return '-';
        const lastDigits = this.station.softwareVersion.substring(this.station.softwareVersion.length - 14);
        return lastDigits.length == 14 ? `...${lastDigits}` : lastDigits;
    }

    // get the stations recommendation - 'no action recommended' if empty
    getRecommendation(): string {
        if (!this.station.recommendation || this.station.recommendation == null) return 'No action recommended';
        return this.station.recommendation;
    }

    // get the correct recommendation icon
    getRecommendationIcon(): string {
        const recommendationIconMap: { [key: string]: string } = {
            'Check SIM Portal': 'sim_card',
            'Check Ticket State': 'description',
            'Create Ticket': 'note_add',
            'No issues detected': 'done',
            'Out of Order': 'clear',
            'Restart Station': 'replay_circle_filled',
        };
        return recommendationIconMap[this.station.recommendation as Recommendation] || 'do_not_disturb_on';
    }

    // get the custom evse id
    getCustomEvseId(): string {
        if(!this.station.featuredConnector || !this.station.featuredConnector.customEvseId) return '-';
        return this.station.featuredConnector.customEvseId;
    }

    // filter out the "-" strings and join the rest with ", "
    getCopyInfo(strings: string[]): string {
        return strings.filter(str => str !== "-").join(", ");
    }
    
    ngOnDestroy(): void {
        // remove current ID when leaving /details/
        this.detailsRepository.setStationId(null)
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
