import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, Subscription, combineLatest, filter, map, take } from 'rxjs';
import { ExportService, NotificationService } from 'src/app/core/app-services';
import { GlobalService } from 'src/app/core/app-services/global.service';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { TrafficLoggerService } from 'src/app/core/data-backend/data-services';
import { ChargingStation } from 'src/app/core/data-backend/models';
import { fadeIn, fadeOut } from 'src/app/core/helpers/animations';
import { appRepository } from 'src/app/core/stores/app.repository';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import { ExportRequestEvent } from 'src/app/shared/export/export.component';
import { Paths, RolePermissions } from 'src/assets/files/roles_access/roles-permissions.types';

@Component({
    selector: 'app-subnav',
    template: `
    <nav>
        <div class="container">
            <div class="subnav">
               
                <a class="subnav-item reduced-width" 
                    routerLink="./" 
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                >
                    <div class="material-icon">ev_station</div>
                </a>
                <a class="subnav-item" routerLink="./timeline" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.timeline'">{{ 'DETAILS_VIEW.TIMELINE.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./health-index" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.healthIndex'">{{ 'DETAILS_VIEW.HEALTH_INDEX.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./errors" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.errors'">{{ 'DETAILS_VIEW.ERRORS.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./sessions" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.sessions'">{{ 'DETAILS_VIEW.SESSIONS.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./tickets" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.tickets'">{{ 'DETAILS_VIEW.TICKETS.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./restarts" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.restarts'">{{ 'DETAILS_VIEW.RESTARTS.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./connection" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.connection'">{{ 'DETAILS_VIEW.CONNECTION.TITLE' | translate }}</a>
                <a class="subnav-item" routerLink="./configuration" routerLinkActive="active" *evcHasPermissions="'routes.stationDetails.configuration'">{{ 'DETAILS_VIEW.CONFIGURATION.TITLE' | translate }}</a>

                <a class="subnav-item spacer"></a>

                @if ((this.detailsRepo.station$ | async); as station) {
                    <div 
                        *evcHasPermissions="'dashboard.table.bulkActions.resetStations'"
                        class="position-relative"
                    >
                        <button
                            class="subnav-item subnav-icon subnav-restart"
                            (click)="openRestart = !openRestart"
                        >
                            <div class="material-icon">sync</div>
                            {{ 'DETAILS_VIEW.RESTARTS.RESTART' | translate }}
                        </button>
                        <app-restart-popup
                            [stationId]="station.stationId"
                            [(openRestart)]="openRestart"
                        ></app-restart-popup>
                    </div>

                    @if (hasExportPermissions$ | async) {
                        @if (exportService.exportVars$ | async; as exportVars) {
                            <div 
                                class="subnav-item subnav-icon position-relative"
                                [class.no-export]="!exportVars.exportCondition"
                                [@fadeInOutAnimation]
                            >
                                <button 
                                    class="subnav-export"
                                    (click)="openExport = !openExport"
                                >
                                    <div class="material-icon">download</div>
                                    {{ 'NAVIGATION.DOWNLOAD' | translate }}
                                </button>
                                <app-export 
                                    [exportOptions]="exportVars.exportOptions"
                                    [showDatePicker]="exportVars.showDatePicker"
                                    [polling]="exportService.pollingInfo$ | async"
                                    [(isOpen)]="openExport"
                                    (onExportRequest)="emitExportRequest($event)"
                                ></app-export>
                            </div>
                        }
                    }

                    <ng-container *ngIf="(appRepo.selectedCustomer$ | async)?.identifier == 'demo'">
                        <button 
                            *evcHasPermissions="'stationDetails.createTicket'"
                            class="subnav-item subnav-icon"
                            (click)="openTicket()"
                        >
                            <div class="material-icon">description</div>
                            {{ 'NAVIGATION.OPEN_TICKET' | translate }}
                        </button>
                    </ng-container>

                    <ng-container *evcHasPermissions="'stationDetails.openBackend'">
                        <a 
                            *ngIf="backendLink$ | async as link"
                            class="subnav-item subnav-icon"
                            href="{{ link.link }}"
                            target="_blank"
                            (click)="sendKeywordToBackend()"
                        >
                            <div class="material-icon">open_in_new</div>
                            {{ 'NAVIGATION.OPEN_IN' | translate: {content: link.title} }}
                        </a>
                    </ng-container>
                }
            </div>
        </div>
    </nav>
    `,
    styleUrls: ['./subnav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInOutAnimation', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),
            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ])
    ]
})
export class SubnavComponent {
    openRestart: boolean = false;
    openExport: boolean = false;
    backendLink$ = this.detailsRepo.station$.pipe(
        filter((station) => station !== null),
        map((station) => this.getBackendLink(station!))
    );
    hasExportPermissions$: Observable<boolean>;

    constructor(
        public detailsRepo: detailsRepository,
        public notificationService: NotificationService,
        public exportService: ExportService,
        public trafficLoggerService: TrafficLoggerService,
        public appRepo: appRepository,
        private _globalService: GlobalService,
        private _permService: PermissionsService
    ) {
        const subviewMapping: Record<string, Paths<RolePermissions>> = {
            'home': 'global.export.stationDetails.home',
            'timeline': 'global.export.stationDetails.timeline',
            'health-index': 'global.export.stationDetails.healthIndex',
            'errors': 'global.export.stationDetails.errors',
            'sessions': 'global.export.stationDetails.sessions',
            'configuration': 'global.export.stationDetails.configuration',
            'restarts': 'global.export.stationDetails.restarts',
            'connection': 'global.export.stationDetails.connection',
            'tickets': 'global.export.stationDetails.tickets'
        }

        // handles export permissions on view change
        this.hasExportPermissions$ = combineLatest({
            route: this._globalService.currentUrl$,
            permissions: this._permService.userPermissions$
        }).pipe(
            map(({route, permissions}) => {
                const routeObj = route.split('/');
                let routeEnd = routeObj[routeObj.length - 1];

                // special case for "stations home", where the routeEnd will be the stationID
                if (routeObj.includes('details') && !Object.keys(subviewMapping).includes(routeEnd)) {
                    routeEnd = 'home';
                }
                const requestPermission = subviewMapping[routeEnd];
                return this._permService.checkPermissions(requestPermission, permissions);
            })
        )
    }

    emitExportRequest(event: ExportRequestEvent) {
        this.exportService.exportRequest$.next(event);
        this.exportService.exportRequest$.next(null);
    }

    getBackendLink(station: ChargingStation): { title: string, link: string } | false {
        if (!station || !station.source || !station.stationId) return false
        const linkTitle = {
            'virta': 'Virta',
            'compleo': 'vaylens'
        }
        if (station.source === 'virta') {
            return {
                title: linkTitle[station.source],
                link: `https://platform.virta.global/stations/list?isArchived=activeOnly&includeEmptyPools=false&typeInFilters=%7B%22query%22%3A%22${station.stationId}%22%2C%22fields%22%3A%5B%5D%7D`
            }
        }
        if (station.source === 'compleo') {
            if (!station.customStationId) return false;
            return {
                title: linkTitle[station.source],
                link: `https://portal.vaylens.com/operate/chargingInfrastructureDetail?uuid=${station.customStationId}&chargingStation=${station.stationId}&selectedChargingStationTab=0`
            }
        }
        return false
    }

    // use traffic logger service to inform backend
    sendKeywordToBackend() {
        this.trafficLoggerService.trafficLogger(
            {'body': {keyword: 'backend'}}
        ).pipe(take(1)).subscribe();
    }

    openTicket() {
        this.notificationService.showInfo('This functionality is not yet ready')
    }
}
