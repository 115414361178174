import { formatDate, formatNumber } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { PlotType } from './plot.models';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStateNameService } from '../app-services/localized-state-name.service';

type TooltipSize = 'large' | 'small';

@Injectable({
    providedIn: 'root'
})
export class PlotTooltipsService {
    private _currentPopup: Element | undefined;
    private _renderer: Renderer2;

    constructor(
        private _rendererFactory: RendererFactory2,
        private _translate: TranslateService,
        private _localizedStateName: LocalizedStateNameService
    ) {
        this._renderer = this._rendererFactory.createRenderer(null, null)
    }

    private _t = (path: string, interpolateParams?: Object): string => {
        return this._translate.instant(path, interpolateParams)
    }

    renderTooltip(event: any, container: Element, size: TooltipSize = 'large') {
        let isEmpty = event.points[0].data.customdata?.isEmpty;
        if (isEmpty === true) return
        
        this.removeTooltip()

        let plotType: PlotType = event.points[0].data.customdata.plotType, // defines format of tooltip
            tooltipText,
            // individual offset for different marker types of plot
            markerDimensions = {width: 0, height: 0}; 

        switch (plotType) {
            case 'overall-state':
                tooltipText = `<span style="line-height: 1.6">${this._localizedStateName.instant(event.points[0].data.hovertext)}</span>`
                markerDimensions.height = 10
                markerDimensions.width = 10
                break
            case 'error': case 'ocpp-messages':
                tooltipText = `<span style="line-height: 1.6">${event.points[0].data.hovertext}<br>${formatDate(event.points[0].x, 'dd.MM.y HH:mm', this._translate.currentLang)}</span>`
                markerDimensions.height = 10
                markerDimensions.width = 10
                break
            case 'error-bar':
                tooltipText = `<span>${event.points[0].x}</span>`
                markerDimensions.height = 36
                markerDimensions.width = -8
                break
            case 'ocpp-aggregation':
                tooltipText = `<span style="line-height: 1.6">${this._t('DETAILS_VIEW.HOME.RECEIVED', {percent: event.points[0].y})}<br>${formatDate(event.points[0].x, 'dd.MM.y HH:mm', this._translate.currentLang)}</span>`
                break
            case 'health-index':
                let conId       = event.points[0].data.name,
                    healthIndex = event.points[0].y,
                    healthDate  = new Date(event.points[0].x.replace(/-/g, '/').replace('T', ' '));

                tooltipText = `<span style="line-height: 1.6">${this._t('COMMON.CONNECTOR.ONE')} ID: ${conId}<br>${this._t('COMMON.MODELS.HEALTH_INDEX')}: ${healthIndex}%<br>${formatDate(healthDate, 'dd.MM.y HH:mm:ss', this._translate.currentLang)}</span>`;
                break
            case 'charging-session':
                let content = event.points[0].data.hovertext,
                    suffix  = event.points[0].yaxis.ticksuffix;

                // if hovertext is empty, show yValue
                tooltipText = content || event.points[0].y;
                // if suffix, append
                tooltipText = `${tooltipText}${suffix ? ' '+suffix : ''}<br>${formatDate(event.points[0].x, 'dd.MM.y HH:mm', this._translate.currentLang)}`;
                break
            case 'charging-stations':
                let state = event.points[0].x,
                    value = event.points[0].y;

                tooltipText = `${this._localizedStateName.instant(state)}<br>${formatNumber(value, this._translate.currentLang)}`
                markerDimensions.width = 20
                break
            case 'kpi-charging-sessions':
                tooltipText = `<span style="line-height: 1.6">${formatNumber(event.points[0].y, this._translate.currentLang)} ${this._t('COMMON.SESSION.OTHER')}<br> ${formatDate(event.points[0].x, 'dd.MM.yy', this._translate.currentLang)}</span>`
                break
            case 'kpi-successful-sessions':
                tooltipText = `<span style="line-height: 1.6">${formatNumber(event.points[0].hovertext.value, this._translate.currentLang)} ${this._t('COMMON.SUCCESSFUL_SESSION.OTHER')}<br> ${formatDate(event.points[0].x, 'dd.MM.yy', this._translate.currentLang)}</span>`
                break
            case 'kpi-chargers-in-failure':
                tooltipText = `<span style="line-height: 1.6">${formatNumber(event.points[0].y, this._translate.currentLang)} ${this._t('COMMON.STATION.OTHER')}<br> ${formatDate(event.points[0].x, 'dd.MM.yy', this._translate.currentLang)}</span>`
                break
            case 'kpi-charged-amount':
                tooltipText = `<span style="line-height: 1.6">${formatNumber(Math.round(event.points[0].y / 1000), this._translate.currentLang)} kWh<br> ${formatDate(event.points[0].x, 'dd.MM.yy', this._translate.currentLang)}</span>`
                break
        }

        if (!tooltipText || tooltipText.length == 0) return

        let eventPoint      = event.points[0],
            x               = (eventPoint.bbox?.x0 || event.event.layerX) + (markerDimensions.width / 2),
            y               = (eventPoint.bbox?.y0 || event.event.layerY) + (markerDimensions.height / 2),
            tooltipClasses  = ['tooltip-container-absolute', 'tooltip-short-delay'],
            plotRect        = event.event.srcElement.getBoundingClientRect(),
            popup           = this._renderer.createElement('div'),
            plotParentLimit = plotRect.x + 0.66 * plotRect.width,
            toLeft          = event.event.x >= plotParentLimit;

        tooltipClasses.push(toLeft ? 'to-left' : 'to-top')
        if (size === "small") {tooltipClasses.push('small')}
        popup.innerHTML = tooltipText;
        popup.setAttribute("class", tooltipClasses.join(' '));
    
        // hide first to get clientHeight without weird visible behaviour of tooltip
        popup.style.visibility = "hidden";
        this._renderer.appendChild(container, popup)
        let popupRect = popup.getBoundingClientRect();

        // position tooltip based on which side to open
        popup.style.top   = Math.round(toLeft ? y - (popupRect.height / 2) : y - popupRect.height - 10).toString() + "px";
        popup.style.left  = Math.round(toLeft ? x - (popupRect.width / 2) - 10 : x + 4).toString() + "px";

        popup.style.visibility = "visible";
        this._currentPopup = popup;
    }
    
    removeTooltip() {
        if (this._currentPopup) {
            this._currentPopup.remove()
        }
    }
}
